import React, { useState, useCallback, useMemo, useContext } from 'react';
import useDepartments from '@ubeya/shared/hooks/account/useDepartments';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const DepartmentFilter = ({ defaultValue, handleClose, onApply, search, onChange, hideLayout = false }) => {
  const { t } = useContext(ConfigContext);
  const { departmentsOptions } = useDepartments();

  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      search
        ? departmentsOptions.filter(
            ({ label }) =>
              t('departments').toLowerCase().includes(search.toLowerCase()) ||
              (label && label.toLowerCase().includes(search.toLowerCase()))
          )
        : departmentsOptions,
    [departmentsOptions, search, t]
  );

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <FilterLayout
      name={t('departments')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={hideLayout}>
      {filteredOptions.map(({ value, label }) => (
        <DropDown.Item key={value}>
          <CheckBox
            useTextWithTooltip
            name={`department-${value}`}
            value={values.includes(value)}
            label={label}
            onChange={() => handleChange(value)}
          />
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default DepartmentFilter;
