export const CHAT_FILTERS_SLUGS = {
  tags: 'chatTagsFilter',
  branches: 'chatBranchesFilter',
  statuses: 'chatStatusesFilter'
};

export const PROJECT_LIVE_VIEW_FILTERS_SLUGS = {
  positions: 'projectLiveViewPositions',
  locations: 'projectLiveViewLocations',
  startTime: 'projectLiveViewStartTime'
};

export const PROJECT_SCHEDULING_FILTERS_SLUGS = {
  positions: 'projectSchedulingPositions',
  locations: 'projectSchedulingLocations',
  startTime: 'projectSchedulingStartTime',
  endTime: 'projectSchedulingEndTime',
  bookingStatus: 'projectSchedulingBookingStatus',
  bookingIssues: 'projectSchedulingBookingIssues',
  suppliers: 'projectSchedulingSuppliers',
  branchCostCenters: 'projectSchedulingBranchCostCenters',
  ageWarning: 'projectSchedulingAgeWarning'
};

export const NON_BOOKED_BOOKING_STATUS = 1;
export const PARTIALLY_BOOKED_BOOKING_STATUS = 2;
export const FULLY_BOOKED_BOOKING_STATUS = 3;

export const BOOKING_STATUS_MAPPING = {
  [NON_BOOKED_BOOKING_STATUS]: 'nonBooked',
  [PARTIALLY_BOOKED_BOOKING_STATUS]: 'partiallyBooked',
  [FULLY_BOOKED_BOOKING_STATUS]: 'fullyBooked'
};

export const WARNING_BOOKING_ISSUE = 'error';
export const NOTICEABLE_BOOKING_ISSUE = 'warning';
export const OK_BOOKING_ISSUE = 'ok';

export const BOOKING_ISSUES_MAPPING = {
  [WARNING_BOOKING_ISSUE]: 'warnings',
  [NOTICEABLE_BOOKING_ISSUE]: 'noticeable',
  [OK_BOOKING_ISSUE]: 'ok'
};

export const OVER_77_WARNING = 'shiftsWithAgeWarnings77';
export const UNDER_18_WARNING = 'shiftsWithAgeWarningsUnder18';

export const GENERAL_FILTERS_SLUGS = {
  BRANCHES: 'branches',
  POSITIONS: 'positions',
  LOCATIONS: 'locations',
  CLIENTS: 'clients',
  COST_CENTER: 'costCenter',
  START_TIMES: 'startTimes',
  END_TIMES: 'endTimes',
  EVENT_BOOKER: 'eventBooker',
  APPROVED: 'approved',
  CLIENT_APPROVAL: 'clientApproval',
  TAGS: 'tags',

  WITH_CLOCK_IN: 'withClockIn',
  WITH_CLOCK_OUT: 'withClockOut',
  WITH_CHECK_IN: 'withCheckIn',
  WITH_CHECK_OUT: 'withCheckout',
  WITH_BREAKS: 'withBreaks'
};

export const FORECASTING_FILTERS_EVENTS_SLUGS = [GENERAL_FILTERS_SLUGS.CLIENTS];

export const TIMESHEET_TAB_FILTERS_SLUGS = [
  GENERAL_FILTERS_SLUGS.POSITIONS,
  GENERAL_FILTERS_SLUGS.LOCATIONS
  // GENERAL_FILTERS_SLUGS.START_TIMES,
  // GENERAL_FILTERS_SLUGS.END_TIMES,
];

export const TIMESHEET_FILTERS_SLUGS = [
  ...TIMESHEET_TAB_FILTERS_SLUGS,
  GENERAL_FILTERS_SLUGS.CLIENTS,
  GENERAL_FILTERS_SLUGS.TAGS,
  GENERAL_FILTERS_SLUGS.EVENT_BOOKER
];
export const PAYROLL_FILTERS_EVENTS_SLUGS = [
  GENERAL_FILTERS_SLUGS.LOCATIONS,
  GENERAL_FILTERS_SLUGS.CLIENTS,
  GENERAL_FILTERS_SLUGS.TAGS,
  GENERAL_FILTERS_SLUGS.EVENT_BOOKER
];

export const PAY_RATES_FILTERS_EVENTS_SLUGS = [GENERAL_FILTERS_SLUGS.CLIENTS];

export const PAYROLL_PROCESS_STATUS = {
  PROCESSED: 'processed',
  PAY_QUERY: 'payQuery',
  OTHER: 'other'
};

// like from backend
export const INVOICE_STATUS = {
  PENDING: 0,
  ISSUED: 1
};
