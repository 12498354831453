import styled from 'styled-components';
import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import { CheckBoxField, ConfirmationModal, FlexColumnCenter } from '@ubeya/shared-web/components';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';

const Wrapper = styled(FlexColumnCenter)`
  gap: 20px;
`;

const ApproveAllWithoutDiscrepanciesConfirmationModal = ({
  approveAllWithoutDiscrepanciesAddTolerance,
  setApproveAllWithoutDiscrepanciesAddTolerance,
  rowsToApproveWithoutErrors = [],
  onClose,
  onConfirm
}) => {
  const { t } = useContext(ConfigContext);

  const onConfirmSubmit = async () => {
    await onConfirm?.(rowsToApproveWithoutErrors, approveAllWithoutDiscrepanciesAddTolerance);

    onClose();
  };

  return (
    <ConfirmationModal
      confirmationModalContainerStyle={{ width: 600, maxWidth: '6000px !important' }}
      title={t('approveAll')}
      disabledConfirm={!rowsToApproveWithoutErrors.length}
      showCancelButton
      confirmText={t('yes')}
      onClose={onClose}
      onConfirm={onConfirmSubmit}
      cancelText={t('no')}
      usePortal>
      <Form
        initialValues={{ approveAllWithoutDiscrepanciesAddTolerance, rowsToApproveWithoutErrors }}
        onSubmit={() => {}}
        render={({
          values: {
            approveAllWithoutDiscrepanciesAddTolerance: approveAllWithoutDiscrepanciesAddToleranceFormValue,
            rowsToApproveWithoutErrors: rowsToApproveWithoutErrorsFormValue = []
          }
        }) => {
          setApproveAllWithoutDiscrepanciesAddTolerance(approveAllWithoutDiscrepanciesAddToleranceFormValue);

          return (
            <Wrapper>
              <div>{t('areYouSureApproveAll', { shifts: rowsToApproveWithoutErrorsFormValue.length })}</div>

              <CheckBoxField
                name="approveAllWithoutDiscrepanciesAddTolerance"
                title={t('approveAllDiscrepanciesUpTo')}
              />
            </Wrapper>
          );
        }}
      />
    </ConfirmationModal>
  );
};

export default ApproveAllWithoutDiscrepanciesConfirmationModal;
