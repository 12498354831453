import parsePhoneNumberFromString from 'libphonenumber-js';
import moment from 'moment';
import { API_DATE_TIME_FORMAT } from '../constants';

const roundNumber = (number) => Math.round((number + Number.EPSILON) * 100) / 100;

const getFormattingMap = ({ dateFormat, timeFormat, currency, billingCurrency, t, shortDateFormat }) => ({
  text: (value) => value,
  number: (value) => (value ? Math.round(value * 100) / 100 : 0),
  boolean: (value) => (value ? t('yes') : t('no')),
  date: (value) => (value ? moment(value, API_DATE_TIME_FORMAT).format(dateFormat) : ''),
  shortDate: (value) => (value ? moment(value, API_DATE_TIME_FORMAT).format(shortDateFormat) : ''),

  dateAndTime: (value, toLocal = false) => {
    if (!value) {
      return '';
    }

    const momentValue = toLocal ? moment.utc(value, API_DATE_TIME_FORMAT).local() : moment(value, API_DATE_TIME_FORMAT);
    return momentValue.format(`${dateFormat}, ${timeFormat}`);
  },
  parsedLocalTime: (value) => (value ? moment.utc(value).local().format(`${dateFormat}, ${timeFormat}`) : ''),
  startAndEndTime: (startTime, endTime = null) =>
    `${moment(startTime).format(timeFormat)}${endTime ? ` - ${moment(endTime).format(timeFormat)}` : ''}`,
  hours: (value) => (value ? moment(value, API_DATE_TIME_FORMAT).format(timeFormat) : ''),
  time: (value) => moment.utc(value * 60 * 1000).format('HH:mm'),
  currency: (value, options) =>
    value || (value === 0 && options?.includeZero)
      ? `${currency}${options?.withSpace ? ' ' : ''}${
          options?.toRound === false ? value.toLocaleString() : roundNumber(value).toLocaleString()
        }`
      : '',
  billingCurrency: (value, options) =>
    value || (value === 0 && options?.includeZero)
      ? `${billingCurrency ?? currency}${options?.withSpace ? ' ' : ''}${
          options?.toRound === false ? value.toLocaleString() : roundNumber(value).toLocaleString()
        }`
      : '',
  percentage: (value) => (value >= 0 ? `${roundNumber(value).toLocaleString()}%` : ''),
  km: (value) => (value >= 0 ? `${roundNumber(value / 1000)}${t('distanceUnit_km_short')}` : ''),
  mi: (value) => (value >= 0 ? `${roundNumber(value / 1609)}${t('distanceUnit_mi_short')}` : ''),
  numberWithComma: (value) => (!isNaN(value) ? value.toLocaleString('en-US') : ''),
  phone: (value) => (value ? parsePhoneNumberFromString(`+${value}`)?.formatNational?.() : ''),
  i18n: (value) => t(value),
  fixedNumber: (value, fixed = 1) => (value ? (value % 1 !== 0 ? value.toFixed(fixed) : Math.round(value)) : 0),
  capitalizeFirstLetter: (value) => (value ? value.charAt(0).toUpperCase() + value.slice(1) : '')
});

export default getFormattingMap;
