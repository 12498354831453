import moment from 'moment';
import styled from 'styled-components';
import { useContext } from 'react';
import ChristmasIcons from '@ubeya/shared-web/assets/holidays/christmas';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';

const SantaHat = styled(ChristmasIcons.LogoSantaHat)`
  position: absolute;
  top: -17px;
  right: -6px;
`;

const SidebarBottomSnowman = styled(ChristmasIcons.SidebarBottomSnowman)`
  display: flex;
  margin-bottom: 20px;
  align-self: center;
`;

const useGetHolidayAccessories = () => {
  const { languageName, countryCode } = useContext(ConfigContext);
  const isChristmas = moment().isBetween(moment('17/12', 'DD/MM'), moment('28/12', 'DD/MM'), 'day', '[]');

  if (languageName === 'he' || countryCode === 'SA') {
    return {
      LogoAccessory: null,
      SidebarBottomAccessory: null
    };
  }

  if (isChristmas) {
    return {
      LogoAccessory: SantaHat,
      SidebarBottomAccessory: SidebarBottomSnowman
    };
  }
  return {
    LogoAccessory: null,
    SidebarBottomAccessory: null
  };
};

export default useGetHolidayAccessories;
