import React, { useContext } from 'react';
import ConfigContext from '../../../contexts/ConfigContext';

const AgeWarningChip = ({ value }) => {
  const { t } = useContext(ConfigContext);

  return <>{value.map((item) => t(item)).join(', ')}</>;
};

export default AgeWarningChip;
