import React, { useCallback, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { isDesktop } from 'react-device-detect';
import { END_DATE, START_DATE } from 'react-dates/constants';
import ConfigContext from '../../contexts/ConfigContext';
import { semiExtraSmall } from '../Typography';

const Wrapper = styled.div`
  position: relative;
  input {
    cursor: pointer;
  }

  .CalendarDay__default {
    border: none;
    ${semiExtraSmall}
  }

  .CalendarDay__today {
    background-color: ${({ theme }) => theme.colors.calendarToday};
  }

  .CalendarDay__blocked_calendar {
    background-color: ${({ theme }) => theme.colors.surface};
    text-decoration: line-through;
    opacity: 0.4;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    color: ${({ theme }) => theme.colors.surface};
    font-weight: bold;
    opacity: 1;
    text-decoration: none;
  }

  .CalendarDay__selected_span {
    background-color: ${({ theme }) => theme.colors.primary}D0;
    color: ${({ theme }) => theme.colors.surface};
    text-decoration: none;
    opacity: 1;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      td {
        pointer-events: none;
      }
    `}
`;

const DateRangePicker = ({
  startDate,
  endDate,
  onChange,
  isDayBlocked,
  disabled,
  className,
  numberOfMonths,
  isDayHighlighted,
  maximumSelectedDays
}) => {
  const { t } = useContext(ConfigContext);
  const [focus, setFocus] = useState(START_DATE);
  const { isRtl } = useContext(ConfigContext);

  const isOutsideRange = useCallback(
    (day) =>
      maximumSelectedDays
        ? focus === END_DATE &&
          (day.isBefore(startDate) || day.isAfter(startDate.clone().add(maximumSelectedDays, 'days')))
        : false,
    [focus, maximumSelectedDays, startDate]
  );

  return (
    <Wrapper className={className} disabled={disabled}>
      <DayPickerRangeController
        startDate={startDate}
        endDate={endDate}
        focusedInput={focus}
        isOutsideRange={isOutsideRange}
        onFocusChange={(value) => setFocus(value ?? START_DATE)}
        onDatesChange={(changedDate) =>
          onChange({
            startDate: changedDate.startDate,
            endDate: focus === END_DATE ? changedDate.endDate : changedDate.startDate
          })
        }
        numberOfMonths={numberOfMonths || (isDesktop ? 2 : 1)}
        hideKeyboardShortcutsPanel
        isRTL={isRtl}
        renderWeekHeaderElement={(day) => t(day.toLowerCase())}
        isDayBlocked={(day) => isDayBlocked?.(day, focus) || (focus === END_DATE && day.isBefore(startDate))}
        isDayHighlighted={isDayHighlighted}
      />
    </Wrapper>
  );
};

export default DateRangePicker;
