import React from 'react';
import useEmploymentTypes from '@ubeya/shared/hooks/generic/useEmploymentTypes';
import { FlexMiddle } from '../../Flex';

const EmploymentTypeChip = ({ value }) => {
  const { mappedEmploymentTypes } = useEmploymentTypes();

  if (!Array.isArray(value)) {
    return <FlexMiddle>{mappedEmploymentTypes[value]?.label || value}</FlexMiddle>;
  }

  return <FlexMiddle>{value.map((item) => mappedEmploymentTypes[item]?.label).join(', ')}</FlexMiddle>;
};

export default EmploymentTypeChip;
