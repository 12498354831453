import React, { useState, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import DropDown from '../../DropDown';
import BaseCheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';
import { OVER_77_WARNING, UNDER_18_WARNING } from '../../../constants';

const CheckBox = styled(BaseCheckBox)`
  flex: 1;
`;

const ProjectAgeWarningFilter = ({ defaultValue, handleClose, onApply, hideLayout = false, search, onChange }) => {
  const { t } = useContext(ConfigContext);
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const options = useMemo(
    () => [
      {
        label: t(OVER_77_WARNING),
        value: OVER_77_WARNING
      },
      {
        label: t(UNDER_18_WARNING),
        value: UNDER_18_WARNING
      }
    ],
    [t]
  );

  const filteredOptions = useMemo(
    () => (search ? options.filter(({ label }) => label.toLowerCase().includes(search.toLowerCase())) : options),
    [options, search]
  );

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <FilterLayout
      name={t('projectSchedulingAgeWarning')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={hideLayout}>
      {filteredOptions.map(({ value, label }) => (
        <DropDown.Item key={value}>
          <CheckBox name={`warning-${value}`} value={values.includes(value)} onChange={() => handleChange(value)}>
            {label}
          </CheckBox>
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default ProjectAgeWarningFilter;
