import React, { useState, useCallback, useContext } from 'react';
import useTranslatedOptions from '@ubeya/shared/hooks/useTranslatedOptions';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const TIMESHEET_CLIENT_APPROVAL = [
  { label: 'no', value: 0 },
  { label: 'yes', value: 1 }
];

const ClientApprovalFilter = ({ defaultValue, handleClose, onApply, search, onChange, hideLayout = false }) => {
  const { t } = useContext(ConfigContext);
  const timesheetClientApprovalOptions = useTranslatedOptions(TIMESHEET_CLIENT_APPROVAL);
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  if (
    !timesheetClientApprovalOptions.length ||
    (search && !t('clientApproval').toLowerCase().includes(search.toLowerCase()))
  ) {
    return null;
  }

  return (
    <FilterLayout
      name={t('clientApproval')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={hideLayout}>
      {timesheetClientApprovalOptions.map(({ value, label }) => (
        <DropDown.Item key={value}>
          <CheckBox
            name={`clientApproval-${value}`}
            value={values.includes(value)}
            label={label}
            onChange={() => handleChange(value)}
          />
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default ClientApprovalFilter;
