import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import useTranslatedOptions from '@ubeya/shared/hooks/useTranslatedOptions';
import { getDatesRange } from '@ubeya/shared/constants';
import { Flex, FlexColumn, FlexColumnCenter } from '../Flex';
import Button from '../Button';
import { Body, body } from '../Typography';
import { card } from '../Card';
import ConfigContext from '../../contexts/ConfigContext';
import BaseDateRangePicker from './DateRangePicker';

const Wrapper = styled(Flex)`
  ${card};

  > div:last-child {
    ${({ isCustom, theme }) => isCustom && `border-left: 1px solid ${theme.colors.gray910}`};
  }
`;

const CalculateButtonWrapper = styled(FlexColumn)`
  ${({ $calculateWithoutOptions }) =>
    $calculateWithoutOptions
      ? css`
          padding-bottom: 18px;
        `
      : css`
          padding: 8px;
          border-top: ${({ theme }) => `1px solid ${theme.colors.border}`}; ;
        `}
`;

const CalculateButton = styled(Button)`
  height: 36px;
  border-radius: 6px;
`;

const CheckBody = styled(Body)`
  color: ${({ theme }) => theme.colors.successSecondary};
`;

const ApplyButton = styled(Button)`
  margin-bottom: 20px;
  margin-top: -10px;
`;

const OptionsList = styled(FlexColumn)`
  row-gap: 5px;
  margin-top: 16px;
  margin-bottom: ${({ $showCalculateButton }) => (!$showCalculateButton ? '16px' : 0)};
`;

const Option = styled(Button).attrs(() => ({ type: 'button' }))`
  ${body};
  text-align: left;
  border-radius: 4px;
  padding: 5px 8px;
  width: 260px;
  margin: 0 8px;
  color: ${({ theme }) => theme.colors.gray200};
  display: flex;
  column-gap: 4px;
  ${({ theme, isActive }) => isActive && `background-color:${theme.colors.eventModal.labelBackground}!important`};
`;

const DateRangePicker = styled(BaseDateRangePicker)`
  .DayPicker__withBorder {
    box-shadow: none;
  }
`;

const DatePickerCalculateButton = ({ calculateWithoutOptions = false, handleChange, disabled }) => {
  const { t } = useContext(ConfigContext);
  return (
    <CalculateButtonWrapper $calculateWithoutOptions={calculateWithoutOptions}>
      <CalculateButton primary narrow onClick={handleChange} disabled={disabled}>
        {t('calculate')}
      </CalculateButton>
    </CalculateButtonWrapper>
  );
};

const DatePickerWithOptions = ({
  value,
  onChange,
  onClose,
  allOption,
  options: newOptions,
  className,
  onCalculateClick,
  numberOfMonths,
  isCustomOptionSingleDay = false,
  openWithOptions = true,
  maximumSelectedDays
}) => {
  const { t } = useContext(ConfigContext);
  const [customRange, setCustomRange] = useState(value);
  const showCalculateButton = !!onCalculateClick;

  const customOptions = useMemo(() => newOptions || (allOption ? { all: {}, ...getDatesRange() } : getDatesRange()), [
    allOption,
    newOptions
  ]);

  const options = useTranslatedOptions(
    Object.entries(customOptions).map(([key, { start, end }]) => ({ key, label: key, start, end }))
  );

  const handleChange = useCallback(
    (e) => {
      e?.stopPropagation();
      if (showCalculateButton) {
        onCalculateClick?.();
      } else {
        onChange(customRange);
      }
      onClose();
    },
    [customRange, onCalculateClick, onChange, onClose, showCalculateButton]
  );

  const handleCustomOptionClick = useCallback(
    ({ startDate, endDate }) => {
      const newCustomRange = { key: isCustomOptionSingleDay ? 'today' : 'custom', start: startDate, end: endDate };
      setCustomRange(newCustomRange);

      if (isCustomOptionSingleDay || showCalculateButton) {
        onChange(newCustomRange);
      }
    },
    [isCustomOptionSingleDay, onChange, showCalculateButton]
  );

  return (
    <Wrapper className={className} isCustom={value?.key === 'custom'}>
      {openWithOptions && (
        <OptionsList $showCalculateButton={showCalculateButton}>
          {options.map(({ key, label, start, end }) => (
            <Option
              key={key}
              tertiary
              isActive={value?.key === key}
              onClick={(e) => {
                e.stopPropagation();
                onChange({ key, start, end });
                if (!showCalculateButton && key !== 'custom') {
                  onClose();
                }
              }}>
              <CheckBody>{value?.key === key ? '✓ ' : ''}</CheckBody>
              {label}
            </Option>
          ))}

          {showCalculateButton && <DatePickerCalculateButton handleChange={handleChange} disabled={!value?.key} />}
        </OptionsList>
      )}

      {value?.key === 'custom' && (
        <FlexColumnCenter>
          <DateRangePicker
            numberOfMonths={numberOfMonths}
            startDate={customRange.start}
            endDate={customRange.end}
            onChange={handleCustomOptionClick}
            isDayBlocked={customOptions.custom.isDayBlocked}
            maximumSelectedDays={maximumSelectedDays}
          />
          {!showCalculateButton && (
            <ApplyButton primary disabled={!customRange.start || !customRange.end} onClick={handleChange}>
              {t('apply')}
            </ApplyButton>
          )}
          {!openWithOptions && showCalculateButton && (
            <DatePickerCalculateButton handleChange={handleChange} disabled={!value?.key} calculateWithoutOptions />
          )}
        </FlexColumnCenter>
      )}
    </Wrapper>
  );
};

export default DatePickerWithOptions;
