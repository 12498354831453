import React from 'react';
import styled from 'styled-components';
import { ArrowContainer, Popover as TinyPopover } from 'react-tiny-popover';

const PopoverContentWrapper = styled.div`
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const Popover = ({
  popoverContent = '',
  triggerContent = '',
  isPopoverOpen,
  onClickOutside,
  popoverContentStyle,
  positions = ['right', 'left'],
  showArrow = true, // Default to showing the arrow
  containerStyle = {
    zIndex: 4,
    top: '6px'
  }
}) => {
  const styledPopoverContent = (
    <PopoverContentWrapper style={popoverContentStyle}>{popoverContent}</PopoverContentWrapper>
  );

  return (
    <TinyPopover
      isOpen={isPopoverOpen}
      positions={positions} // preferred positions by priority
      padding={10}
      containerStyle={containerStyle}
      align="start"
      onClickOutside={onClickOutside} // Use the custom click outside handler
      content={({ position, childRect, popoverRect }) =>
        showArrow ? (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="white"
            arrowSize={6}
            arrowStyle={{
              marginTop: -7
            }}
            className="popover-arrow-container"
            arrowClassName="popover-arrow">
            {styledPopoverContent}
          </ArrowContainer>
        ) : (
          styledPopoverContent
        )
      }>
      {triggerContent}
    </TinyPopover>
  );
};

export default Popover;
