import React from 'react';
import useDepartments from '@ubeya/shared/hooks/account/useDepartments';
import { FlexMiddle } from '../../Flex';

const DepartmentChip = ({ value }) => {
  const { mappedDepartments } = useDepartments();

  if (!Array.isArray(value)) {
    return <FlexMiddle>{mappedDepartments[value] || value}</FlexMiddle>;
  }

  return <FlexMiddle>{value.map((item) => mappedDepartments[item]).join(', ')}</FlexMiddle>;
};

export default DepartmentChip;
