import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import emptyStateUniformImage from '@ubeya/shared-web/assets/empty-state-uniform.svg';
import { FlexColumn, Flex, TextWithTooltip } from '@ubeya/shared-web/components';

const breakWordTextToLineCss = css`
  max-height: calc(1.4em * 3); /* 1.4em is the line height, multiplied by 3 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal; /* Ensure text wraps properly */

  word-wrap: break-word; /* Break long words only if they are too wide */
  word-break: break-word; /* Ensure long unbreakable strings break into multiple lines */
`;
const Label = styled(TextWithTooltip)`
  font-weight: 600;
  font-size: 14px;
  width: 100%;

  ${breakWordTextToLineCss};
`;

const Wrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  height: 105px;
`;

const Description = styled(TextWithTooltip)`
  font-size: 14px;
  line-height: 1.4;
  ${breakWordTextToLineCss};
`;

const Content = styled(FlexColumn)``;

const imageProps = css`
  width: 90px;
  height: 90px;
  margin-right: 10px;
  border-radius: 4px;
  object-fit: cover; /* Crops the image to fill the area */
`;

const Image = styled.img`
  ${imageProps};
`;

const Placeholder = styled.div`
  ${imageProps};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
`;

const ImageWrapper = styled.div`
  position: relative;
  ${imageProps};
`;

const ImageWithLoader = ({ src, fallbackSrc }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <ImageWrapper>
      {!isLoaded && <Placeholder />}
      <Image
        src={src || fallbackSrc}
        onLoad={() => setIsLoaded(true)}
        onError={() => setIsLoaded(true)} // Hide loader even on error
        style={{ display: isLoaded ? 'block' : 'none' }} // Hide until loaded
      />
    </ImageWrapper>
  );
};

const UniformCard = ({ label, image, description, wrapperStyle, labelStyle }) => {
  return (
    <Wrapper style={wrapperStyle}>
      <ImageWithLoader src={image} fallbackSrc={emptyStateUniformImage} />

      <Content>
        <Label useScrollHeight style={labelStyle}>
          {label}
        </Label>

        <Description useScrollHeight>{description}</Description>
      </Content>
    </Wrapper>
  );
};

export default UniformCard;
