import { useMemo } from 'react';
import { useQuery } from 'react-query';
import * as api from '../../services/api';
import useCRUD from '../useCRUD';
import useAccount from './useAccount';

const useDepartments = () => {
  const { accountId } = useAccount();
  const storeKey = ['departments', accountId];
  const { isLoading, data } = useQuery(storeKey, () => api.fetchDepartments({ accountId }), { enabled: !!accountId });

  const departmentsOptions = useMemo(() => (data?.data || []).map(({ id, name }) => ({ value: id, label: name })), [
    data?.data
  ]);

  const mappedDepartments = useMemo(
    () => departmentsOptions.reduce((prev, { label, value }) => ({ ...prev, [value]: label }), {}),
    [departmentsOptions]
  );

  const { addItem, editItem, deleteItem } = useCRUD(storeKey, {
    addApi: (department) => api.addDepartment({ accountId, department }),
    editApi: ({ id, ...department }) => api.updateDepartment({ accountId, departmentId: id, department }),
    deleteApi: ({ id }) => api.deleteDepartment({ accountId, departmentId: id })
  });

  return {
    isLoading,
    departments: data?.data || [],
    departmentsOptions,
    mappedDepartments,
    deleteDepartment: deleteItem,
    addDepartment: addItem,
    editDepartment: editItem
  };
};

export default useDepartments;
